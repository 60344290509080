import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from 'styled-system';
import { Field, ErrorMessage } from 'formik';
import tag from 'clean-tag';

import Flex from '../../components/Flex';
import Box from '../../components/Box';
import Text from '../../components/Text';

import blacklist from '../../components/utils/blacklist';

const TextInput = styled(tag)`
  font-family: inherit;
  width: 100%;
  padding: 0 0.25em;
  border: none;
  line-height: 1.75;
  border-radius: 0;
  font-weight: bold;
  font-size: 1em;
  &::placeholder {
    color: ${themeGet('colors.gray')};
  }
`;

TextInput.defaultProps = {
  blacklist,
};

const Input = ({
  label,
  labelWidth,
  name,
  placeholder,
  disabled,
  value,
  onChange,
  onBlur,
  error,
  type,
  min,
  max,
  step,
  textAlign,
  is,
  ...props
}) => (
  <Flex is="label" {...props}>
    {label && (
      <Box mr="1em" width={labelWidth} textAlign={textAlign}>
        <Text fontWeight="bold" lineHeight="1.75">{label}</Text>
      </Box>
    )}
    <Box flex="1">
      {createElement(TextInput, {
        is: Field,
        name,
        disabled,
        placeholder,
        type,
        min,
        max,
        step,
        component: is,
      })}
      <Text fontSize="0.8em" textAlign="left" color="red" mt="0.25rem">
        <ErrorMessage name={name} />
      </Text>
    </Box>
  </Flex>
);

Input.propTypes = {
  label: PropTypes.node,
  labelWidth: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onKeyUp: PropTypes.func,
  onBlur: PropTypes.func,
};

Input.displayName = 'Input';

export default Input;
