import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

import Box from './Box';
import Text from './Text';

const Input = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
`;

const LabelBox = styled(Box)`
  cursor: pointer;
  padding-left: 2em;
  display: inline-block;
  position: relative;
  font-size: 1em;
  letter-spacing: 0.15em;
  .checkmark {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 1.1em;
    width: 1.1em;
    font-size: 1.25em;
    box-sizing: border-box;
    border: 1px solid black;
    border-radius: 50%;
    background-color: white;
    &:after {
      opacity: 0;
      content: "";
      position: absolute;
      left: 0;
      right:0;
      top: 0;
      bottom: 0;
      margin: auto;
      width: 0.1em;
      height: 0.1em;
      background: ${({ theme }) => theme.colors.yellow};
      box-sizing: content-box;
      transition: all 0.25s ease;
      width: 0.25em;
      height: 0.5em;
      border-radius: 50%;
      box-sizing: content-box;
    }
  }
  input:checked + .checkmark {
    &:after {
      display: block;
      width: 0.5em;
      height: 0.5em;
      opacity: 1;
    }
  }
`;

function Checkbox({
  children,
  name,
  error,
  onChange,
  labelWidth,
  label,
  value,
  ...props
}) {
  return (
    <LabelBox is="label" {...props}>
      <Input onChange={onChange} name={name} value={value} />
      <span className="checkmark" />
      {children}
      {error && <Text color="red" fontSize="0.8em" position="absolute" left="1em" bottom="-2em">{error}</Text>}
      <Box ml="0.5em" width={labelWidth}>
        <Text fontWeight="bold">{label}</Text>
      </Box>
    </LabelBox>
  );
}


Checkbox.propTypes = {
  children: PropTypes.node,
  name: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
};

export default Checkbox;

