import React from 'react';
import set from 'lodash/set'
import { Formik, Form, FieldArray, ErrorMessage } from 'formik'
import { object, string, array, setLocale } from 'yup';
import mapValues from 'lodash/mapValues'

import Box from '../../components/Box'
import Button from '../../components/Button'
import Text from '../../components/Text'
import Title from '../../components/Title'
import CheckBox from '../../components/CheckBox'
import Flex from '../../components/Flex'
import Modal from '../../components/Modal'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme';
import storeForm from '../../services/googleSheets/storeForm'
import FormikField from './FormikField'

setLocale({
  mixed: {
    required: '此題必填',
  },
  string: {
    email: 'E-mail格式錯誤',
  },
})

const datas = ['姓名', '年齡', '學校', '科系', '年級', 'Email']
const interestings = ['超高齡社會', '面對移民/移工', '產業創新與轉型', '臺灣的區域定位', '建構知識社會', '媒體識讀挑戰']

const shape = datas.reduce((s, d, i, { length }) => {
  return set(s, `n${i}`, i === length - 1 ? string().email().required() : string().required());
}, {
  interest: array().of(string()).required(),
});

const schema = object().shape(shape)

const Action = ({
  isOpen,
  formIsStored,
  onRequestClose,
  requestFormStorage,
  rowId,
}) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      {formIsStored ? (
        <Box p="2em">
          <Text fontSize="2em">已經收到你的資料！</Text>
        </Box>
      ) : (
        <Formik
          validationSchema={schema}
          initialValues={{ ...mapValues(shape, () => ''), interest: [] }}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            requestFormStorage(Object.keys(shape).map((k) => k === 'interest' ? values.interest.join() : values[k]).concat(rowId));
          }}
        >
          {({ values }) => (
            <Box px={mobileOrDesktop('0', '2em')}>
              <Box align="center" borderBottom="1px solid" pb="0.5em">
                <Title
                  fontSize={mobileOrDesktop('0.75em', '1em')}
                  my={mobileOrDesktop('0.25em', '0.5em')}>一起行動！</Title>
                <Text>填寫以下表單，我們將把更詳細的資訊寄送給你</Text>
              </Box>
              <Form>
                <Box fontSize={mobileOrDesktop('0.8em', '1em')}>
                  <Flex my={mobileOrDesktop('1em', '2em')} flexWrap="wrap" justifyContent="space-between">
                    {datas.map((d, i) => (
                      <Box p={mobileOrDesktop('0.25em', '0.75em')} key={i} width={mobileOrDesktop(1, 1 / 2)}>
                        <FormikField textAlign="right" labelWidth="3.5em" label={d} name={`n${i}`} />
                      </Box>
                    ))}
                  </Flex>
                  <Box pl="0.5em">
                    <Text>六大議題中感興趣的項目(複選)</Text>
                    <Text color="red" fontSize="0.8em">
                      <ErrorMessage name="interest" />
                    </Text>
                  </Box>
                  <FieldArray name="interest">
                    {(arrayHelpers) => (
                      <Flex my="0.5em" flexWrap="wrap" justifyContent="flex-start">
                        {interestings.map((int, i) => (
                          <Box p={mobileOrDesktop('0.25em', '0.75em')} key={i} width={mobileOrDesktop(1 / 2, 1 / 3)}>
                            <CheckBox
                              labelWidth="10em"
                              label={int}
                              name="interest"
                              value={int}
                              checked={values.interest.includes(int)}
                              onChange={e => {
                                if (e.target.checked) {
                                  arrayHelpers.push(int);
                                } else {
                                  const idx = values.interest.indexOf(int);
                                  arrayHelpers.remove(idx);
                                }
                              }}
                            />
                          </Box>
                        ))}
                      </Flex>
                    )}
                  </FieldArray>
                </Box>
                <Text fontSize={mobileOrDesktop('0.6em', '0.75em')} lineHeight="1.5">上述個人資料之蒐集、處理及利用，將僅限本獎學金計畫及財團法人林堉璘宏泰教育基金會寄發資料及內部使用，並遵守個資法之規定妥善保護您的個人資訊。</Text>
                <Box textAlign="center" my={mobileOrDesktop('0.25em', '1.5em')}>
                  <Button type="submit" py={mobileOrDesktop('0.25em', '0.5em')} px="1.5em">確定送出</Button>
                </Box>
              </Form>
            </Box>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default storeForm(Action);
