import React, { PureComponent } from 'react'
import { compose } from 'redux';

import Action from './Action';
import logo from './logo.svg';
import result from './result.svg';
import resultmobile from './result-mobile.svg';

import withQuestionReducer from '../QuestionPage/hoc';

import questions from '../QuestionPage/questions.json';
import Layout from '../Layout';
import Container from '../../components/Container'
import Box from '../../components/Box'
import Text from '../../components/Text'
import Image from '../../components/Image'
import OverlayWithScroll from '../../components/OverlayWithScroll'
import Button from '../../components/Button'
import BackgroundImage from '../../components/BackgroundImage'
import VerticalCenter from '../../components/VerticalCenter'
import { mobileOrDesktop } from '../../components/ThemeProvider/theme';
import Link from '../../components/Link'

import withReducer from './hoc'
import withResponsive from '../../hoc/withResponsive'

class IndexPage extends PureComponent {
  state = {

  }

  handleOpen = () => {
    this.setState({ openModal: true })
    this.clickTrack('我想行動')
  }

  closeModal = () => {
    this.setState({ openModal: false })
  }

  clickTrack = (label) => {
    if (window.ga) {
      window.ga('send', 'event', '結果頁', label);
    }
  }

  render() {
    const { score, isMobile, formIsStored, rowId, index } = this.props;
    const { openModal } = this.state
    return (
      <Layout>
        <BackgroundImage src={isMobile ? resultmobile : result} height="100%">
          <VerticalCenter>
            <Container py={mobileOrDesktop('1em', '2em')} align="center" fontSize="1.25em">
              <Text.h2>
                你的認知與現實的距離
              </Text.h2>
              <Box fontWeight="bold" my="0.5em">
                {score} / {questions.length} 題
              </Box>
              <Box
                bg="gray"
                py="0.5em"
                borderRadius="1em"
                position="relative"
                border="1px solid"
              >
                <Box
                  position="absolute"
                  bg="yellow"
                  borderRadius="1em"
                  top="0"
                  bottom="0"
                  width={score / questions.length}
                />
              </Box>
              <Box fontSize={mobileOrDesktop('0.8em', '1em')}>
                <Box
                  bg="gray"
                  px={mobileOrDesktop('1.5em', '3em')}
                  py={mobileOrDesktop('1.5em', '2em')}
                  height="40vh"
                  my={mobileOrDesktop('1em', '2em')}
                  border="1.5px solid"
                  borderRadius="3em"
                >
                  <OverlayWithScroll>
                    <strong>剛剛這些問題你答對了幾題?</strong><br />
                    媒體報導，英國市調組織易普索莫里(Ipsos MORI)公布 2016 年無知指數(The 2016 Index of Ignorance)，臺灣為調查對象 40 個國家中的第 3 名。乍看之下好像很聳動，但當我們看到這則訊息時，我們是否有試著去思考及驗證呢？還是一味地接收了訊息，甚至接受了無知的標籤？<br />
                    當資訊爆炸的時代來臨，許多重要的資訊反而被埋沒，因此在步調緊湊的現代生活中懂得篩選資訊、吸收真正重要的資訊，便是媒體識讀的一大挑戰。<br/>
                    媒體識讀就是對於媒體資訊具辨識、質疑與批判能力，讓我們的認知能盡可能地貼近現實，因此我們需要培養理解資訊、判斷哪些資訊重要，並將點點滴滴的資訊整合組成整體的世界觀的能力。<br/>
                    發現問題是解決問題的第一步，在更認識了未來10年人口遽變下臺灣將面臨的挑戰後，除了能重新檢視自己的認知與當前世界發展的差距，你也可以將面對未來的焦慮化為力量，和我們一起實踐你的創新想法，讓臺灣變得更好！
                  </OverlayWithScroll>
                </Box>
                <Text
                  mx="1em"
                  textAlign={mobileOrDesktop('left', 'center')}
                  fontWeight="bold"
                >
                  你想更了解超高齡社會的臺灣將面臨哪些挑戰嗎？<br/>
                  你想要投入其中、捲起袖子跟我們一起用創新的方案因應這些挑戰嗎？<br/>
                  立即點擊「我想行動」，讓<Text.inline bg="yellow">堉璘獎學金</Text.inline>幫你一起實踐你/妳的點子，讓臺灣變得更好！
                </Text>
                <Box>
                  <Button
                    m="0.75em"
                    px={mobileOrDesktop('1.75em', '2em', '1em')}
                    py="0.5em"
                    disabled={formIsStored}
                    onClick={this.handleOpen}
                  >
                    我想行動
                  </Button>
                  <Button
                    m="0.75em"
                    px={mobileOrDesktop('1.75em', '2em', '1em')}
                    py="0.5em"
                    is={Link}
                    onClick={() => this.clickTrack('分享出去')}
                    href={`https://www.facebook.com/sharer.php?u=https%3A%2F%2F2030.relab.cc%2Findex${index}`}
                  >
                    分享出去
                  </Button>
                </Box>
              </Box>
              <Box width={mobileOrDesktop('100%', '80%')}>
                <Image src={logo} />
              </Box>
              <Action
                isOpen={openModal}
                onRequestClose={this.closeModal}
                formIsStored={formIsStored}
                rowId={rowId}
              />
            </Container>
          </VerticalCenter>
        </BackgroundImage>
      </Layout>
    )
  }
}

export default compose(withQuestionReducer, withReducer, withResponsive)(IndexPage)
